import { ColorStatusType } from "@/constants/colors.constants";
import { ChipStyle } from "../common.model";

export interface CustomerOrder {
	id: string;
	createdAt: string;
	type: string;
	description: string;
	state: OrderStates;
	isExpanded?: boolean;
}

export interface CustomerOrderDetail {
	id: string;
	hierarchy: OrderHierarchy[];
}

export interface OrderHierarchy {
	id: string;
	provisioningId?: string;
	level: number;
	type: string;
	state: string;
	createdAt: string;
	createdBy?: string;
	referenceName?: string;
}

enum OrderStates {
	ACKNOWLEDGED = "Acknowledged",
	CANCELLATION_IN_PROGRESS = "CancellationInProgress",
	CANCELLED = "Cancelled",
	COMPLETED = "Completed",
	FAILED = "Failed",
	IN_PROGRESS = "InProgress",
	NEW = "New"
}

export const OrderStateConfig: Record<OrderStates, ChipStyle> = {
	[OrderStates.ACKNOWLEDGED]: {
		color: ColorStatusType.GREY,
		background: ColorStatusType.GREY_LIGHT
	},
	[OrderStates.CANCELLATION_IN_PROGRESS]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.ORANGE_LIGHT
	},
	[OrderStates.CANCELLED]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.RED
	},
	[OrderStates.COMPLETED]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.GREEN_LIGHT
	},
	[OrderStates.FAILED]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.RED
	},
	[OrderStates.IN_PROGRESS]: {
		color: ColorStatusType.GREY,
		background: ColorStatusType.WHITE
	},
	[OrderStates.NEW]: {
		color: ColorStatusType.GREY,
		background: ColorStatusType.GREY_LIGHT
	}
};
