import { productChangeOfAddressInitialState } from "../productChangeOfAddress";

const CLEAR_PRODUCT_CHANGE_OF_ADDRESS_PROCESS = (state: any) => {
	Object.assign(state, productChangeOfAddressInitialState());
};

const SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS = (state: any, payload: any) => {
	state.changeOfAddress = {
		...state.changeOfAddress,
		address: {
			...state.changeOfAddress.address,
			id: payload?.coverage?.addressId ?? "-1"
		},
		apartment: {
			...state.changeOfAddress.apartment,
			apartmentId: payload?.coverage?.apartmentId ?? "-1"
		},
		notes: payload?.notes ?? "",
		intermediateProduct: {
			...state.changeOfAddress.intermediateProduct,
			salesPerson: payload?.intermediateProduct?.salesPerson ?? "",
			targetDate: payload?.intermediateProduct?.targetDate ?? ""
		}
	};
};

const SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_ID = (state: any, payload: any) => {
	state.id = payload;
};

const SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_STATUS = (state: any, payload: string) => {
	state.status = payload;
};

const SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_COVERAGE_SEARCH_TERM = (state: any, payload) => {
	state.changeOfAddress.coverage.search = payload;
};

const SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_COVERAGE_SEARCH_TYPE = (state: any, payload) => {
	state.changeOfAddress.coverage.searchType = payload;
};

const SET_PRODUCT_CHANGE_OF_ADDRESS_SHOPPING_CART_ID = (state: any, payload) => {
	state.shoppingCartId = payload;
};

const SET_PRODUCT_CHANGE_OF_ADDRESS_PROVINCE = (state: any, payload: string) => {
	state.province = payload;
};

export default {
	CLEAR_PRODUCT_CHANGE_OF_ADDRESS_PROCESS,
	SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS,
	SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_ID,
	SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_STATUS,
	SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_COVERAGE_SEARCH_TERM,
	SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_COVERAGE_SEARCH_TYPE,
	SET_PRODUCT_CHANGE_OF_ADDRESS_SHOPPING_CART_ID,
	SET_PRODUCT_CHANGE_OF_ADDRESS_PROVINCE
};
