import { RouteInfo } from "./types";
import { authGuard, permissionGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewRetail = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/retail/Retail.vue");
const RetailSectionIbanValidation = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/retail/iban-validation/IbanValidation.vue");
const Apartments = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/retail/apartments/Apartments.vue");

export const RetailRouteChildren: RouteInfo[] = [
	{
		id: UIPAGE.RETAIL_IBAN_VALIDATION,
		path: "iban-validation",
		component: RetailSectionIbanValidation,
		title: "retail.children.iban-validation.display"
	},
	{
		id: UIPAGE.RETAIL_APARTMENTS,
		path: "apartments",
		component: Apartments,
		title: "retail.children.apartments.display"
	}
];

export const RetailRoute: RouteInfo = {
	id: UIPAGE.RETAIL,
	path: "/retail",
	component: ViewRetail,
	guards: [authGuard, permissionGuard],
	title: "retail.display",
	icon: "mdi-store-outline",
	children: [...RetailRouteChildren]
};
