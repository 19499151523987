import { configurationToConfigurationAPI } from "../adapters/configuration.adapter";
import httpClient from "../config/httpClient";

export abstract class ProductChangeOfAddressService {
	private static readonly BASE_URL = "sys03/customers/products/change-of-address-processes";
	private static readonly ADDRESSES_URL = this.BASE_URL.concat("/addresses");

	public static get(id: number) {
		return httpClient.get(`${this.BASE_URL}/${id}`);
	}

	public static create(payload: any) {
		return httpClient.post(this.BASE_URL, payload);
	}

	public static update(productId: number, payload: any) {
		return httpClient.patch(`${this.BASE_URL}/${productId}`, payload);
	}

	public static getAddressesByZip({ zip }: { zip: string }): any {
		const url = `${this.ADDRESSES_URL}/zip/${zip}`;
		return httpClient.get(url);
	}

	public static getAddressesByCoordinates({ lng, lat }: { lng: string; lat: string }): any {
		const url = `${this.ADDRESSES_URL}/area`;
		return httpClient.get(url, {
			timeout: 25000,
			params: { lng, lat }
		});
	}

	public static getAddressesById({ id }: { id: string }): any {
		const url = `${this.ADDRESSES_URL}/${id}`;
		return httpClient.get(url);
	}

	public static getByApartmentId(apartmentId: string): any {
		const url = `${this.ADDRESSES_URL}/apartments/${apartmentId}`;
		return httpClient.get(url);
	}

	public static getChangeOfAddressIntermediateSummary({ shoppingCartId }: { shoppingCartId: string }) {
		const url = `/${this.BASE_URL}/intermediate-shopping-carts/${shoppingCartId}/summary`;
		return httpClient.get(url);
	}

	public static getChangeOfAddressIntermediateConfiguration({ shoppingCartId }: { shoppingCartId: string }) {
		const url = `/${this.BASE_URL}/intermediate-shopping-carts/${shoppingCartId}/configuration`;
		return httpClient.get(url);
	}

	public static putChangeOfAddressIntermediateConfiguration({
		shoppingCartId,
		bundles = [],
		parameters = []
	}: {
		shoppingCartId: string;
		bundles: any[];
		parameters: any;
	}) {
		const url = `/${this.BASE_URL}/intermediate-shopping-carts/${shoppingCartId}/configuration`;
		return httpClient.put(url, configurationToConfigurationAPI({ bundles, parameters }));
	}

	public static send(id: string) {
		const url = `/${this.BASE_URL}/${id}/send-intermediate`;
		return httpClient.put(url);
	}
}
