import { UIPAGE } from "@/constants/ui-page.constants";
import { RouteInfo } from "../types";
import { authGuard, permissionGuard } from "../guards/index";

const ViewCustomer360 = () => import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/Customer360.vue");

const CustomerSectionOrders = () =>
	import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/components/CustomerOrders.vue");
const CustomerSectionFinances = () =>
	import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/components/CustomerFinances.vue");
const CustomerSectionTasks = () => import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/components/CustomerTasks.vue");
const CustomerSectionNotes = () => import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/components/CustomerNotes.vue");
const CustomerSectionChargesCredits = () =>
	import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/components/CustomerChargesCredits.vue");
const CustomerSectionFollowUp = () =>
	import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/components/CustomerFollowUp.vue");
const CustomerSectionProducts = () =>
	import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/components/CustomerProducts.vue");
const CustomerSectionAggrupations = () =>
	import(/* webpackChunkName: "chunk-customer-360" */ "@/views/customer/customer-360/components/CustomerAggrupations.vue");

export const Customer360RouteChildren: RouteInfo[] = [
	{
		id: UIPAGE.CUSTOMER_360_FOLLOW_UP,
		path: "follow-up",
		component: CustomerSectionFollowUp,
		title: "customers.customer_360.follow_up.display"
	},
	{
		id: UIPAGE.CUSTOMER_360_PRODUCTS,
		path: "products",
		component: CustomerSectionProducts,
		title: "customers.products.display"
	},
	{
		id: UIPAGE.CUSTOMER_360_CHARGES_CREDITS,
		path: "charges-credits",
		component: CustomerSectionChargesCredits,
		title: "customers.customer_360.charges_credits.display"
	},
	{
		id: UIPAGE.CUSTOMER_360_ORDERS,
		path: "orders",
		component: CustomerSectionOrders,
		title: "customers.customer_360.orders.display"
	},
	{
		id: UIPAGE.CUSTOMER_360_FINANCES,
		path: "finances",
		component: CustomerSectionFinances,
		title: "customers.customer_360.finances.display"
	},
	{
		id: UIPAGE.CUSTOMER_360_TASKS,
		path: "tasks",
		component: CustomerSectionTasks,
		title: "customers.customer_360.tasks.display"
	},
	{
		id: UIPAGE.CUSTOMER_360_NOTES,
		path: "notes",
		component: CustomerSectionNotes,
		title: "customers.customer_360.notes.display"
	},
	{
		id: UIPAGE.CUSTOMER_360_AGGRUPATIONS,
		path: "aggrupations",
		component: CustomerSectionAggrupations,
		title: "customers.customer_360.aggrupations.display"
	},
	{
		id: "customer-empty",
		path: "",
		redirect: "follow-up"
	}
];

export const Customer360Route: RouteInfo = {
	id: UIPAGE.CUSTOMER_360,
	path: "/customers/:id",
	component: ViewCustomer360,
	guards: [authGuard, permissionGuard],
	props: (route) => ({
		id: route.query.id
	}),
	children: Customer360RouteChildren
};
