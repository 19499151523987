import actions from "./actions";
import addressActions from "./actions/addressActions";
import mutations from "./mutations";
import getters from "./getters";
import { Apartment } from "@/api/models/address/apartment.model";
import { Address } from "@/api/models/address/address.model";
import { Coordinates } from "@/api/models/address/coordinates.model";

interface ProductChangeOfAddressState {
	id: number;
	status: string;
	changeOfAddress: {
		coverage: {
			search: string;
			searchType: string;
		};
		address: {
			id: string;
			address: string;
			salesArea: string;
			status: string;
			lat: string;
			lng: string;
		};
		apartment: Apartment;
		notes: string;
		intermediateProduct: {
			salesPerson: string;
			targetDate: string;
		};
	};
	addresses: Address[];
	addressSearchedCoords: Coordinates;
	apartments: Apartment[];
	shoppingCartId?: string;
	province?: string;
	availableNumbers?: string[];
}

export const productChangeOfAddressInitialState = (): ProductChangeOfAddressState => {
	return {
		id: -1,
		status: "",
		changeOfAddress: {
			coverage: {
				search: "",
				searchType: ""
			},
			address: {
				id: "-1",
				address: "",
				salesArea: "",
				status: "",
				lat: "",
				lng: ""
			} as Address,
			apartment: {
				apartmentId: "-1",
				floor: "",
				door: "",
				stair: "",
				network: ""
			} as Apartment,
			notes: "",
			intermediateProduct: {
				salesPerson: "",
				targetDate: ""
			}
		},
		addresses: [],
		addressSearchedCoords: {
			lat: -1,
			lng: -1
		},
		apartments: [],
		shoppingCartId: undefined,
		province: undefined,
		availableNumbers: []
	};
};

const state: ProductChangeOfAddressState = productChangeOfAddressInitialState();

export default {
	state,
	actions,
	addressActions,
	mutations,
	getters
};
