import { UIPAGE } from "@/constants/ui-page.constants";
import { RouteInfo } from "../types";
import { authGuard, permissionGuard } from "../guards/index";

const ViewCustomerProductChangeOfAddress = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-change-of-address-1st-phase" */ "@/views/customer/product-change-of-address/CustomerProductChangeOfAddress.vue"
	);

const CustomerProductChangeOfAddressCoverageStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-change-of-address-1st-phase" */ "@/views/customer/product-change-of-address/components/CoverageStep.vue"
	);

const CustomerProductChangeOfAddressIntermediateConfigurationStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-change-of-address-1st-phase" */ "@/views/customer/product-change-of-address/components/IntermediateConfigurationStep.vue"
	);

const CustomerProductChangeOfAddressIntermediateOrderStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-change-of-address-1st-phase" */ "@/views/customer/product-change-of-address/components/OrderStep.vue"
	);

export const CustomerProductChangeOfAddressRoute: RouteInfo = {
	id: UIPAGE.CUSTOMER_PRODUCT_CHANGE_OF_ADDRESS,
	path: "/customers/:customerId/products/:productId/change-of-address",
	component: ViewCustomerProductChangeOfAddress,
	guards: [authGuard, permissionGuard /* , customerProductMultipleTransitionStatusGuard */],
	props: (route) => ({
		customerId: route.query.customerId,
		productId: route.query.productId
	}),
	children: [
		{
			id: UIPAGE.CUSTOMER_PRODUCT_CHANGE_OF_ADDRESS_ID_COVERAGE,
			path: ":processId/coverage",
			component: CustomerProductChangeOfAddressCoverageStep,
			title: "customers.products.change_of_address.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_CHANGE_OF_ADDRESS_ID_INTERMEDIATE_CONFIGURATION,
			path: ":processId/intermediate-configuration",
			component: CustomerProductChangeOfAddressIntermediateConfigurationStep,
			title: "customers.products.change_of_address.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_CHANGE_OF_ADDRESS_ID_INTERMEDIATE_ORDER,
			path: ":processId/intermediate-order",
			component: CustomerProductChangeOfAddressIntermediateOrderStep,
			title: "customers.products.change_of_address.display"
		}
	]
};
