import { Address } from "@/api/models/address/address.model";

const getProductChangeOfAddress = (state) => {
	return state;
};

const getProductChangeOfAddressShoppingCartId = (state) => {
	return state.shoppingCartId;
};

const getProductChangeOfAddressStatus = (state) => {
	return state.status;
};

const mapMarkersProductChangeOfAddress = (state) => {
	const addresses = state.addresses.filter((address: Address, index: number, self) => {
		return index === self.findIndex((selfAddress) => selfAddress.id === address.id);
	});
	const markers = addresses.map((address) => {
		return {
			id: address.id,
			position: {
				lat: Number(address.lat),
				lng: Number(address.lng)
			}
		};
	});
	return markers ? markers : [];
};

const addressesToMarkProductChangeOfAddress = (state) => {
	const addresses = state.addresses.filter((address: Address, index: number, self) => {
		return index === self.findIndex((selfAddress) => selfAddress.id === address.id);
	});
	return addresses;
};

const areAddressesResultsProductChangeOfAddress = (state) => {
	return state.addresses.length > 0 || state.apartments.length > 0;
};

export default {
	getProductChangeOfAddress,
	getProductChangeOfAddressShoppingCartId,
	getProductChangeOfAddressStatus,
	mapMarkersProductChangeOfAddress,
	addressesToMarkProductChangeOfAddress,
	areAddressesResultsProductChangeOfAddress
};
