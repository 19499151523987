import {
	CustomerNewExtrasProcessStatus,
	ProductChangeOfAddressProcessStatus,
	ProductModificationProcessStatus,
	ProductTransitionProcessStatus
} from "@/api/constants/enums";
import { ProductActionType } from "@/api/models/customer";
import { ProductModificationProduct } from "@/api/models/product-modification";
import { ProductInstanceAction } from "@/constants/constants";
import store from "@/store";

export const ProductActionsMixin = {
	/**
	 * Validate method to follow up actions
	 * Dispatch: getCustomerProductsActionOrderValidateById
	 */
	validateProductActionOrder(actionType: ProductActionType, id: string) {
		return this.validateAction("getCustomerProductsActionOrderValidateById", actionType, id);
	},
	/**
	 * Validate method to customer products actions
	 * Dispatch: getCustomerProductsActionDuplicateValidateById
	 */
	validateProductActionDuplicate(actionType: ProductActionType, id: string) {
		return this.validateAction("getCustomerProductsActionDuplicateValidateById", actionType, id);
	},
	/**
	 * Validate method to customer products actions
	 * Dispatch: getCustomerProductsActionValidateById
	 */
	validateProductAction(actionType: ProductActionType, id: string) {
		if (actionType == ProductActionType.NEW_EXTRAS) return this.validateNewExtras(id);

		return this.validateAction("getCustomerProductsActionValidateById", actionType, id);
	},
	async validateAction(dispatchName: string, actionType: ProductActionType, productId: string) {
		store.commit("activateOverlay", `customers.products.${actionType.toString().toLowerCase()}.loading.display`);
		store.commit("clearApartment");
		store.commit("updateStepperStep", 1);
		return store.dispatch(dispatchName, { id: productId, action: actionType.toString().toUpperCase() });
	},

	async validateNewExtras(customerId: string) {
		store.commit("activateOverlay", `customers.new_extras.loading.display`);
		store.commit("clearApartment");
		return store.dispatch("getCustomerAvailableExtrasValidateById", customerId);
	},

	async setProductStatusToFresh(actionType: ProductActionType, actionId: string) {
		const status = this.getFreshStatusByProductAction(actionType);
		return store
			.dispatch(this.getUpdateDispatchNameByProductAction(actionType), {
				id: actionId,
				data: { status }
			})
			.then(() => {
				this.saveStatus(actionType, status);
			});
	},
	async saveStatus(actionType: ProductActionType, status: string) {
		switch (actionType) {
			case ProductActionType.TRANSITION:
				return store.dispatch("saveProductTransitionStatus", status);
			case ProductActionType.TRANSITION_ONLY_MOBILES:
			case ProductActionType.TRANSITION_SHARED_MOBILES:
				return store.dispatch("saveProductMultipleTransitionStatus", status);
			case ProductActionType.MODIFICATION:
				return store.dispatch("saveProductModificationStatus", status);
			case ProductActionType.NEW_EXTRAS:
				return store.dispatch("saveNewExtrasStatus", status);
		}
	},
	getFreshStatusByProductAction(actionType: ProductActionType): string {
		switch (actionType) {
			case ProductActionType.TRANSITION:
			case ProductActionType.TRANSITION_SHARED_MOBILES:
			case ProductActionType.TRANSITION_ONLY_MOBILES:
				return ProductTransitionProcessStatus.FRESH;
			case ProductActionType.MODIFICATION:
				return ProductModificationProcessStatus.FRESH;
			case ProductActionType.NEW_EXTRAS:
				return CustomerNewExtrasProcessStatus.FRESH;
			case ProductActionType.CHANGE_OF_ADDRESS:
				return ProductChangeOfAddressProcessStatus.FRESH;
			default:
				return "";
		}
	},
	getUpdateDispatchNameByProductAction(actionType: ProductActionType): string {
		switch (actionType) {
			case ProductActionType.TRANSITION:
				return "updateProductTransition";
			case ProductActionType.TRANSITION_ONLY_MOBILES:
			case ProductActionType.TRANSITION_SHARED_MOBILES:
				return "updateMultipleTransition";
			case ProductActionType.MODIFICATION:
				return "updateProductModification";
			case ProductActionType.NEW_EXTRAS:
				return "updateNewExtras";
			case ProductActionType.CHANGE_OF_ADDRESS:
				return "updateProductChangeOfAddressProcess";
			default:
				return "";
		}
	},
	getProductActionPath(actionType: ProductActionType, customerId: string, productId: string, processId?: string, processStep?: string) {
		switch (actionType) {
			case ProductActionType.CHANGE_OF_ADDRESS:
				return `/customers/${customerId}/products/${productId}/change-of-address/${processId}/${processStep ? processStep : "coverage"}`;
			case ProductActionType.CONTINUE_CHANGE_OF_ADDRESS:
				return `/customers/${customerId}/products/${productId}/continue-change-of-address/${processId}/${processStep ? processStep : "promotions"}`;
			case ProductActionType.CANCEL_CHANGE_OF_ADDRESS:
				return `/customers/${customerId}/products/${productId}/cancel-change-of-address/${processId}/${processStep ? processStep : "order"}`;
			case ProductActionType.TRANSITION:
				return `/customers/${customerId}/products/${productId}/transition/${processId != undefined ? processId + "/" : ""}${
					processStep ? processStep : "bundle"
				}`;
			case ProductActionType.TRANSITION_ONLY_MOBILES:
				return `/customers/${customerId}/products/${productId}/only-mobiles-transition/${processId != undefined ? processId + "/" : ""}${
					processStep ? processStep : "bundle"
				}`;
			case ProductActionType.TRANSITION_SHARED_MOBILES:
				return `/customers/${customerId}/products/${productId}/shared-mobiles-transition/${processId != undefined ? processId + "/" : ""}${
					processStep ? processStep : "bundle"
				}`;
			case ProductActionType.MODIFICATION:
				return `/customers/${customerId}/products/${productId}/modification/${processId != undefined ? processId + "/" : ""}${
					processStep ? processStep : "bundle"
				}`;
			case ProductActionType.TERMINATION:
				return `/customers/${customerId}/products/${productId}/termination`;
			case ProductActionType.NEW_EXTRAS:
				return `/customers/${customerId}/new-extras/${processId != undefined ? processId + "/" : ""}${processStep ? processStep : "bundle"}`;
		}
	},
	getCancelActionLabel(action: ProductInstanceAction): string {
		switch (action) {
			case ProductInstanceAction.DELETE:
				return "customers.products.change.steps.step1.cancel_terminate_instance";
			case ProductInstanceAction.SWAP:
				return "customers.products.change.steps.step1.cancel_swap_mobiles";
			case ProductInstanceAction.MAKE_PRINCIPAL:
				return "customers.products.change.steps.step1.cancel_make_principal";
			default:
				return "common.cancel";
		}
	},
	getDisabledCancelActionLabel(action: ProductInstanceAction): string {
		switch (action) {
			case ProductInstanceAction.DELETE:
			case ProductInstanceAction.SWAP:
			case ProductInstanceAction.MAKE_PRINCIPAL:
				return "customers.products.change.steps.step1.disabled_cancel_terminate_instance";
			default:
				return "common.disabled_cancel";
		}
	},
	getNoChangeInstancesLength(product: ProductModificationProduct): number {
		return (product.instances || []).filter(
			(instance) =>
				[ProductInstanceAction.NO_CHANGE].includes(instance.action) || (instance.action === ProductInstanceAction.ADD && instance.referenceProductId)
		).length;
	},
	getActualInstancesLength(product: ProductModificationProduct): number {
		return (product.instances || []).filter((instance) => [ProductInstanceAction.NO_CHANGE, ProductInstanceAction.ADD].includes(instance.action))
			.length;
	}
};
