import { SaleProcessStatus, SearchType, GooglePlaceDataType } from "@/api/constants/enums";
import store from "@/store/index";
import { ProductChangeOfAddressService } from "@/api/services/productChangeOfAddressProcess.service";

const translatePath = "customers.products.change_of_address.steps.step1";

const loadChangeOfAddressesByPostalCode = ({ commit }: any, { zip }: { zip: string }) => {
	activateOverlay(commit, `${translatePath}.searching_addresses`);
	return new Promise((resolve, reject) => {
		return ProductChangeOfAddressService.getAddressesByZip({ zip })
			.then((response) => {
				if (response.status === 204) {
					resolve(null);
				} else {
					commit("SAVE_ADDRESSES", response.data);
					resolve(response.data);
				}
			})
			.catch(reject)
			.finally(() => deactivateOverlay(commit));
	});
};

const loadChangeOfAddressesByGooglePlace = ({ commit }: any, { googlePlace }: { googlePlace: any; allStatuses: boolean }) => {
	activateOverlay(commit, `${translatePath}.searching_addresses`);
	return new Promise((resolve, reject) => {
		const coordinates = googlePlace?.geometry.location.toJSON();
		return ProductChangeOfAddressService.getAddressesByCoordinates({ lng: coordinates?.lng, lat: coordinates?.lat })
			.then((response) => {
				if (response.status === 200) {
					commit("SAVE_ADDRESSES", response.data);
					resolve(response.data);
				} else if (response.status === 204) {
					resolve(null);
				}
			})
			.catch(reject)
			.finally(() => deactivateOverlay(commit));
	});
};

const loadChangeOfAddressesByCoordinates = ({ commit }: any, { lng, lat }: { lng: string; lat: string }) => {
	activateOverlay(commit, `${translatePath}.searching_addresses`);
	return new Promise((resolve, reject) => {
		return ProductChangeOfAddressService.getAddressesByCoordinates({ lng, lat })
			.then((response) => {
				commit("SET_ADDRESS_SEARCHED_COORDS", {
					lat: Number(lat),
					lng: Number(lng)
				});
				if (response.status === 200) {
					commit("SAVE_ADDRESSES", response.data);
					resolve(response.data);
				} else if (response.status === 204) {
					resolve(null);
				}
			})
			.catch(reject)
			.finally(() => deactivateOverlay(commit));
	});
};

const getChangeOfAddressesAddressById = ({ commit }: any, { id }: { id: string; allStatuses: boolean }) => {
	activateOverlay(commit, `${translatePath}.searching_addresses`);
	return new Promise((resolve, reject) => {
		return ProductChangeOfAddressService.getAddressesById({ id })
			.then((response) => {
				if (typeof response.data === "object") {
					commit("UPDATE_ADDRESS", response.data);
					commit("SAVE_APARTMENTS", response.data.apartments);
					resolve(response.data);
				} else {
					commit("UPDATE_ADDRESS", undefined);
					resolve(undefined);
				}
			})
			.catch((error) => {
				commit("UPDATE_ADDRESS", undefined);
				commit("UPDATE_APARTMENT", undefined);
				reject(error);
			})
			.finally(() => deactivateOverlay(commit));
	});
};

const searchChangeOfAddressesByGooglePlace = async ({ commit, dispatch }: any, { googlePlace }: { googlePlace: any }) => {
	activateOverlay(commit, `${translatePath}.searching_addresses`);
	resetAddressData(commit);

	try {
		const coordinates = googlePlace?.geometry.location.toJSON();
		const response = await ProductChangeOfAddressService.getAddressesByCoordinates({ lng: coordinates?.lng, lat: coordinates?.lat });

		if (response.status === 200) {
			commit("SAVE_ADDRESSES", response.data);
			const formattedAddress = formatAddressSearchByGoogle(googlePlace);
			commit("SET_ADDRESS_SEARCHED_COORDS", {
				lat: Number(formattedAddress.googleLatitude),
				lng: Number(formattedAddress.googleLongitude)
			});
			const productChangeOfAddressId = (store["state"] as any).productChangeOfAddressProcess.id;
			if (response && productChangeOfAddressId !== "") {
				const changeOfAddressProcess = {
					id: productChangeOfAddressId,
					data: {
						status: SaleProcessStatus.ADDRESS_SEARCH,
						coverage: formatAddressSearchByGoogle(googlePlace)
					}
				};
				if (changeOfAddressProcess.data.coverage.googleZip === "") {
					changeOfAddressProcess.data.coverage.googleZip = undefined;
				}

				dispatch("updateProductChangeOfAddressProcess", changeOfAddressProcess);
			}
		} else if (response.status === 204) {
			return null;
		}
	} catch (error) {
		commit("UPDATE_ADDRESS", undefined);
		throw error;
	} finally {
		deactivateOverlay(commit);
	}
};

const searchChangeOfAddressesByCoordinates = async ({ commit, dispatch }: any, { lng, lat }: { lng: string; lat: string }) => {
	activateOverlay(commit, `${translatePath}.searching_addresses`);
	resetAddressData(commit);

	try {
		const response = await ProductChangeOfAddressService.getAddressesByCoordinates({ lng, lat });
		commit("SET_ADDRESS_SEARCHED_COORDS", {
			lat: Number(lat),
			lng: Number(lng)
		});
		if (response.status === 200) {
			const productChangeOfAddressId = (store["state"] as any).productChangeOfAddressProcess.id;
			if (response && productChangeOfAddressId !== "") {
				const changeOfAddressProcess = {
					id: productChangeOfAddressId,
					data: {
						status: SaleProcessStatus.ADDRESS_SEARCH,
						coverage: {
							searchType: SearchType.COORDS,
							googleLatitude: String(lat),
							googleLongitude: String(lng)
						}
					}
				};
				dispatch("updateProductChangeOfAddressProcess", changeOfAddressProcess);
			}
			commit("SAVE_ADDRESSES", response.data);
			return response.data;
		} else if (response.status === 204) {
			return null;
		}
	} catch (error) {
		commit("UPDATE_ADDRESS", undefined);
		throw error;
	} finally {
		deactivateOverlay(commit);
	}
};

const searchChangeOfAddressesByPostalCode = async ({ commit, dispatch }: any, { zip }: { zip: string }) => {
	activateOverlay(commit, `${translatePath}.searching_addresses`);
	resetAddressData(commit);

	try {
		const response = await ProductChangeOfAddressService.getAddressesByZip({ zip });

		if (response.status === 204) {
			return null;
		} else {
			commit("SAVE_ADDRESSES", response.data);
			const productChangeOfAddressId = (store["state"] as any).productChangeOfAddressProcess.id;

			const productChangeOfAddressProcess = {
				id: productChangeOfAddressId,
				data: {
					status: SaleProcessStatus.ADDRESS_SEARCH,
					coverage: {
						searchType: SearchType.ZIP,
						search: zip
					}
				}
			};
			await dispatch("updateProductChangeOfAddressProcess", productChangeOfAddressProcess);
			return response.data;
		}
	} catch (error) {
		commit("UPDATE_ADDRESS", undefined);
		throw error;
	} finally {
		deactivateOverlay(commit);
	}
};

const getApartmentByIdChangeOfAddressesWithoutStore = ({ commit }: any, apartmentId: string) => {
	return new Promise((resolve, reject) => {
		return ProductChangeOfAddressService.getByApartmentId(apartmentId)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getApartmentByIdChangeOfAddresses = ({ commit }: any, apartmentId: string) => {
	return new Promise((resolve, reject) => {
		return ProductChangeOfAddressService.getByApartmentId(apartmentId)
			.then((response) => {
				if (response.status === 204) {
					commit("UPDATE_ADDRESS", undefined);
					commit("UPDATE_APARTMENT", undefined);
					resolve(null);
				} else {
					commit("UPDATE_APARTMENT", response.data);
					if (response.data.streetAddressId !== "0" && Number(response.data.streetAddressId) !== 0) {
						commit("UPDATE_ADDRESS", { id: response.data.streetAddressId });
						resolve(response.data);
					} else {
						commit("UPDATE_ADDRESS", { id: "0" });
						commit("SAVE_APARTMENTS", [response.data]);
						commit("SAVE_ADDRESSES", [
							{
								id: "0",
								lat: response.data.lat,
								lng: response.data.lng
							}
						]);
						resolve(response.data);
					}
				}
			})
			.catch((error) => {
				commit("UPDATE_ADDRESS", undefined);
				commit("UPDATE_APARTMENT", undefined);
				reject(error);
			});
	});
};

const searchChangeOfAddressesApartmentById = ({ commit, dispatch }: any, apartmentId: string) => {
	activateOverlay(commit, `${translatePath}.searching_apartment`);
	return new Promise((resolve, reject) => {
		return dispatch("getApartmentByIdChangeOfAddresses", apartmentId)
			.then((apartment) => {
				if (!apartment) {
					commit("RESET_ADDRESSES_AND_APARTMENTS");
					resolve(null);
				}

				activateOverlay(commit, `${translatePath}.saving`);
				const id = (store["state"] as any).productChangeOfAddressProcess.id;
				const productChangeOfAddressProcess = {
					id,
					data: {
						status: SaleProcessStatus.APARTMENT_SELECTED,
						coverage: {
							apartmentId: apartmentId.toString(),
							searchType: SearchType.ID,
							search: apartmentId.toString()
						}
					}
				};
				resolve(productChangeOfAddressProcess);
			})
			.catch(reject)
			.finally(() => {
				deactivateOverlay(commit);
			});
	});
};

const searchChangeOfAddressesByAddressId = async ({ commit, dispatch }: any, { id }: { id: string }) => {
	activateOverlay(commit, `${translatePath}.searching_apartments`);

	try {
		const response = await ProductChangeOfAddressService.getAddressesById({ id });

		if (response.status === 200) {
			const productChangeOfAddressId = (store["state"] as any).productChangeOfAddressProcess.id;
			commit("SAVE_APARTMENTS", response.data.apartments);
			if (productChangeOfAddressId !== "") {
				const productChangeOfAddressProcess = {
					id: productChangeOfAddressId,
					data: {
						status: SaleProcessStatus.ADDRESS_SELECTED,
						coverage: {
							addressId: id.toString()
						}
					}
				};
				await dispatch("updateProductChangeOfAddressProcess", productChangeOfAddressProcess);
				return response.data;
			} else {
				return null;
			}
		} else if (response.status === 204) {
			return null;
		}
	} finally {
		deactivateOverlay(commit);
	}
};

const selectApartmentChangeOfAddress = ({ commit }: any, apartmentId: string) => {
	return new Promise((resolve, reject) => {
		activateOverlay(commit, `${translatePath}.saving`);

		const fetchData = async () => {
			ProductChangeOfAddressService.getByApartmentId(apartmentId)
				.then((response) => {
					if (response.status === 204) {
						resolve(null);
					} else {
						const productChangeOfAddressId = (store["state"] as any).productChangeOfAddressProcess.id;

						const productChangeOfAddressProcess = {
							status: SaleProcessStatus.APARTMENT_SELECTED,
							coverage: {
								apartmentId
							}
						};

						ProductChangeOfAddressService.update(productChangeOfAddressId, productChangeOfAddressProcess)
							.then((response) => {
								commit("UPDATE_APARTMENT", response.data);

								resolve(response);
							})
							.catch((error) => {
								reject(error);
							})
							.finally(() => {
								deactivateOverlay(commit);
							});
					}
				})
				.catch((error) => {
					reject(error);
				});
		};

		fetchData();
	});
};

const activateOverlay = (commit: any, message: string) => {
	commit("activateOverlay", message);
};

const deactivateOverlay = (commit: any) => {
	commit("desactivateOverlay");
};

const resetAddressData = (commit: any) => {
	commit("UPDATE_ADDRESS", undefined);
	commit("UPDATE_APARTMENT", undefined);
	commit("RESET_ADDRESSES_AND_APARTMENTS");
};

function formatAddressSearchByGoogle(googlePlace: any) {
	const coordinates = googlePlace?.geometry.location.toJSON();
	return {
		searchType: SearchType.GOOGLE,
		search: googlePlace.formatted_address,
		googleStreet: googlePlace.formatted_address,
		googleLatitude: "" + coordinates.lat,
		googleLongitude: "" + coordinates.lng,
		googleProvince: getGooglePlaceData(googlePlace, GooglePlaceDataType.PROVINCE),
		googleZip: getGooglePlaceData(googlePlace, GooglePlaceDataType.ZIP),
		googleCity: getGooglePlaceData(googlePlace, GooglePlaceDataType.CITY)
	};
}

function getGooglePlaceData(googlePlace: any, type: GooglePlaceDataType) {
	const addressComponent = googlePlace.address_components.find((component) => component.types.some((componentType) => componentType === type));
	return addressComponent ? addressComponent.long_name : "";
}

const setAddressSearchedCoords = ({ commit }: any, payload: any) => {
	commit("SET_ADDRESS_SEARCHED_COORDS", payload);
};

export default {
	loadChangeOfAddressesByGooglePlace,
	loadChangeOfAddressesByCoordinates,
	loadChangeOfAddressesByPostalCode,
	searchChangeOfAddressesByGooglePlace,
	searchChangeOfAddressesByCoordinates,
	searchChangeOfAddressesByPostalCode,
	searchChangeOfAddressesApartmentById,
	searchChangeOfAddressesByAddressId,
	selectApartmentChangeOfAddress,
	getChangeOfAddressesAddressById,
	setAddressSearchedCoords,
	getApartmentByIdChangeOfAddresses,
	getApartmentByIdChangeOfAddressesWithoutStore
};
