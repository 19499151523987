import { ColorStatusType } from "@/constants/colors.constants";
import { ChipStyle } from "../common.model";
import { IdentificationType } from "../sale/identification.model";

export interface IndividualCustomerParty {
	title?: string;
	firstName?: string;
	lastName?: string;
	birthDate?: string;
	identification?: CustomerAccountIdentification;
}

export interface OrganizationCustomerParty {
	legalName?: string;
	tradingName: string;
	identification: CustomerAccountIdentification;
}

export interface Customer {
	id: string;
	state: AccountState;
	type: CustomerType;
	party?: IndividualCustomerParty | OrganizationCustomerParty;
	debt: boolean;
}

export interface DashboardCustomer {
	customerId: string;
	customerType?: CustomerType;
	state: AccountState;
	firstName?: string;
	lastName?: string;
	legalName?: string;
	tradingName?: string;
	identification?: CustomerAccountIdentification;
	mobileNumber?: string;
	voipNumber?: string;
}

export interface CustomerAccountIdentification {
	type: IdentificationType;
	number: string;
	country: string;
}

export interface SearchCustomerFilters {
	firstName: string;
	lastName: string;
	legalName: string;
	tradingName: string;
	identificationNumber: string;
	mobileNumber: string;
	voipNumber: string;
	customerId: string;
	email: string;
	limit?: number;
	page?: number;
}

export enum CustomerType {
	BUSINESS = "BUSINESS",
	RESIDENTIAL = "RESIDENTIAL"
}

enum CustomerStates {
	NEW = "New",
	PENDING_ACTIVE = "PendingActive",
	ACTIVE = "Active",
	PENDING_DISCONNECT = "PendingDisconnect",
	DISCONNECTED = "Disconnected",
	CLOSED = "Closed"
}

export const CustomerStateConfig: Record<CustomerStates, ChipStyle> = {
	[CustomerStates.NEW]: {
		color: ColorStatusType.GREY,
		background: ColorStatusType.GREY_LIGHT
	},
	[CustomerStates.PENDING_ACTIVE]: {
		color: ColorStatusType.GREY,
		background: ColorStatusType.GREY_LIGHT
	},
	[CustomerStates.ACTIVE]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.GREEN_LIGHT
	},
	[CustomerStates.PENDING_DISCONNECT]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.RED_LIGHT
	},
	[CustomerStates.DISCONNECTED]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.RED_LIGHT
	},
	[CustomerStates.CLOSED]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.RED_LIGHT
	}
};

enum AccountState {
	NEW = "New",
	PENDING_ACTIVE = "PendingActive",
	ACTIVE = "Active",
	PENDING_DISCONNECT = "PendingDisconnect",
	DISCONNECTED = "Disconnected",
	CLOSED = "Closed"
}
