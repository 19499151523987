import { BulkService } from "@/api/services";

const billingProcess = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		BulkService.billingProcess({ file })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const createPaymentsProcess = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		BulkService.createPaymentsProcess({ file })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const uploadBillsToFtp = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		BulkService.uploadBillsToFtp({ file })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const createCustomerNote = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		BulkService.createCustomerNote({ file })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const createCustomerTask = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		BulkService.createCustomerTask({ file })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const updateCustomerBillingState = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		BulkService.updateCustomerBillingState({ file })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const sendBillsByEmailB2B = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		BulkService.sendBillsByEmailB2B({ file })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const sendBillsByEmailB2C = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		BulkService.sendBillsByEmailB2C({ file })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

export default {
	billingProcess,
	createPaymentsProcess,
	createCustomerNote,
	createCustomerTask,
	sendBillsByEmailB2B,
	sendBillsByEmailB2C,
	updateCustomerBillingState,
	uploadBillsToFtp
};
