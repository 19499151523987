import { ProductChangeOfAddressProcessStatus } from "@/api/constants/enums";
import { ProductChangeOfAddressService } from "@/api/services/productChangeOfAddressProcess.service";

const createProductChangeOfAddressProcess = ({ commit }: any, payload: any) => {
	commit("activateOverlay", "customers.products.change_of_address.steps.step1.saving");
	return new Promise((resolve, reject) => {
		ProductChangeOfAddressService.create(payload)
			.then((response) => {
				if (response.data.id) commit("SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_ID", response.data.id);

				commit("SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_STATUS", ProductChangeOfAddressProcessStatus.FRESH);
				resolve(response);
			})
			.catch(reject)
			.finally(() =>
				setTimeout(() => {
					commit("desactivateOverlay");
				}, 1000)
			);
	});
};

const updateProductChangeOfAddressProcess = ({ commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		ProductChangeOfAddressService.update(payload.id, payload.data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getProductChangeOfAddressProcess = ({ commit }: any, id: number) => {
	return new Promise((resolve, reject) => {
		ProductChangeOfAddressService.get(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveProductChangeOfAddressProcess = ({ commit }: any, changeOfAddress: any) => {
	commit("SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS", changeOfAddress);
};

const saveProductChangeOfAddressProcessStatus = ({ commit }: any, status: string) => {
	commit("SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_STATUS", status);
};

const saveProductChangeOfAddressProcessId = ({ commit }: any, id: string) => {
	commit("SET_PRODUCT_CHANGE_OF_ADDRESS_PROCESS_ID", id);
};

const getChangeOfAddressIntermediateSummary = ({ commit }: any, { shoppingCartId }: { shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductChangeOfAddressService.getChangeOfAddressIntermediateSummary({ shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getChangeOfAddressIntermediateConfiguration = ({ commit }: any, { shoppingCartId }: { shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductChangeOfAddressService.getChangeOfAddressIntermediateConfiguration({ shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveProductChangeOfAddressShoppingCartId = ({ commit }: any, shoppingCartId: string) => {
	commit("SET_PRODUCT_CHANGE_OF_ADDRESS_SHOPPING_CART_ID", shoppingCartId);
};

const updateProductChangeOfAddressIntermediateConfiguration = (
	{ commit }: any,
	payload: { shoppingCartId: string; bundles: any[]; parameters: any[] }
) => {
	return new Promise((resolve, reject) => {
		ProductChangeOfAddressService.putChangeOfAddressIntermediateConfiguration(payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const sendChangeOfAddress = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ProductChangeOfAddressService.send(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

export default {
	createProductChangeOfAddressProcess,
	updateProductChangeOfAddressProcess,
	getProductChangeOfAddressProcess,
	saveProductChangeOfAddressProcess,
	saveProductChangeOfAddressProcessStatus,
	saveProductChangeOfAddressProcessId,
	getChangeOfAddressIntermediateSummary,
	getChangeOfAddressIntermediateConfiguration,
	saveProductChangeOfAddressShoppingCartId,
	updateProductChangeOfAddressIntermediateConfiguration,
	sendChangeOfAddress
};
