import { TransitionGroupMobile } from "@/api/models/product-transition/productTransitionGroup.model";
import { ProductTransition, Transition } from "@/api/models/product-transition/productTransition.model";
import { DeepPartial } from "@/utils/common.util";
import { ProductModificationProduct } from "@/api/models/product-modification";
import { ProductInstanceAction } from "@/constants/constants";
import { ProductType } from "@/api/constants/enums";

function getTransition({
	groupId,
	fiberBandwidth,
	voipQty,
	mobile
}: {
	groupId?: number;
	fiberBandwidth?: string;
	voipQty: number;
	mobile?: TransitionGroupMobile;
}): Partial<Transition> {
	return {
		groupId,
		fiber: fiberBandwidth ? { bandwidth: fiberBandwidth } : undefined,
		voip: {
			qty: voipQty
		},
		mobile
	};
}

function updateVoipProducts<T>({
	transition,
	bundles = []
}: {
	transition?: Transition;
	bundles: (T & { products: ProductModificationProduct[] })[];
}): undefined | T[] {
	if (!transition) return;

	const { voip: { qty: transitionVoipQty = 0 } = {} } = transition;
	let transitionVoipQtyCopy = transitionVoipQty;

	// handle required products
	bundles.forEach((bundle) => {
		const { products = [] } = bundle;

		bundle.products = products.map((product) => {
			const { type, min = 0, max = 0, instances = [] } = product;
			if (type != ProductType.VOIP) return product;
			if (min === 0) return product;

			// Special case: the customer has contracted two voips and only wants to keep one
			if (instances.length > 1 && transitionVoipQtyCopy == 1) {
				product.qty = instances.length;
				product.instances = product.instances?.map((instance) => {
					return {
						...instance,
						action: ProductInstanceAction.NO_CHANGE
					};
				});

				transitionVoipQtyCopy -= 1;
				return product;
			}

			const qty = Math.min(max, transitionVoipQtyCopy);
			// Other cases
			product.qty = qty;
			product.instances = product.instances?.map((instance, index) => {
				return {
					...instance,
					action: index >= qty ? ProductInstanceAction.DELETE : ProductInstanceAction.NO_CHANGE
				};
			});

			transitionVoipQtyCopy -= qty;
			return product;
		});
	});

	// handle optional products
	bundles.forEach((bundle) => {
		const { products = [] } = bundle;

		bundle.products = products.map((product) => {
			const { type, min = 0, instances = [] } = product;
			if (type != ProductType.VOIP) return product;
			if (min > 0) return product;

			// Special case: the customer has contracted two voips and only wants to keep one
			if (instances.length > 1 && transitionVoipQtyCopy == 1) {
				product.qty = instances.length;
				product.instances = product.instances?.map((instance) => {
					return {
						...instance,
						action: ProductInstanceAction.NO_CHANGE
					};
				});

				transitionVoipQtyCopy -= 1;
				return product;
			}

			// Other cases
			product.qty = transitionVoipQtyCopy;
			product.instances = product.instances?.map((instance, index) => {
				return {
					...instance,
					action: index >= transitionVoipQtyCopy ? ProductInstanceAction.DELETE : ProductInstanceAction.NO_CHANGE
				};
			});

			transitionVoipQtyCopy -= 1;
			return product;
		});
	});

	return bundles;
}

export const TransitionMixin = {
	getTransition,
	getProductTransition(productId: string, transition: Transition, shoppingCartId: string): DeepPartial<ProductTransition> {
		return {
			product: {
				id: productId
			},
			transition: transition,
			shoppingCartId: shoppingCartId
		};
	},
	updateVoipProducts
};
