import { CustomerAggrupation } from "@/api/models/customer";

import store from "@/store";

export const AggrupationActionsMixin = {
	async validateAggrupationById(aggrupation: CustomerAggrupation) {
		store.commit("activateOverlay", `customers.customer_360.aggrupations.loading.display`);
		return store.dispatch("getCustomerAggrupationValidateById", { aggrupation });
	},
	async validateAggrupationByCustomerId(customerId: string) {
		store.commit("activateOverlay", `customers.customer_360.aggrupations.loading.display`);
		return store.dispatch("getCustomerAggrupationValidate", { customerId });
	}
};
