import { Address } from "@/api/models/address/address.model";
import { Apartment } from "@/api/models/address/apartment.model";
import { Coordinates } from "@/api/models/address/coordinates.model";

const SAVE_ADDRESSES = (state: any, addresses: Address[]) => {
	state.addresses = addresses;
};

const SAVE_APARTMENTS = (state: any, apartments: Apartment[]) => {
	state.apartments = apartments;
};

const RESET_ADDRESSES_AND_APARTMENTS = (state: any) => {
	state.addresses = [];
	state.apartments = [];
	state.addressSearchedCoords = { lat: -1, lng: -1 };
};

const SET_ADDRESS_SEARCHED_COORDS = (state: any, position: Coordinates) => {
	state.addressSearchedCoords = position;
};

const UPDATE_ADDRESS = (state: any, address: Address) => {
	if (address) {
		state.changeOfAddress.address.id = address.id;
		state.changeOfAddress.address.address = address.address;
		state.changeOfAddress.address.salesArea = address.salesArea;
		state.changeOfAddress.address.status = address.status;
		state.changeOfAddress.address.lat = address.lat;
		state.changeOfAddress.address.lng = address.lng;
	} else {
		state.changeOfAddress.address = {
			id: "-1",
			address: "-1",
			salesArea: "-1",
			status: "-1",
			lat: "-1",
			lng: "-1"
		};
	}
};

const UPDATE_APARTMENT = (state: any, apartment: any) => {
	if (apartment) {
		state.changeOfAddress.apartment.apartmentId = apartment.apartmentId ? apartment.apartmentId : apartment.id;
		state.changeOfAddress.apartment.streetType = apartment.streetType;
		state.changeOfAddress.apartment.streetName = apartment.streetName;
		state.changeOfAddress.apartment.streetNumber = apartment.streetNumber;
		state.changeOfAddress.apartment.floor = apartment.floor;
		state.changeOfAddress.apartment.stair = apartment.stair;
		state.changeOfAddress.apartment.door = apartment.door;
		state.changeOfAddress.apartment.network = apartment.network;
		state.changeOfAddress.apartment.serviceableStatus = apartment.serviceableStatus;
		state.changeOfAddress.apartment.salesAreaName = apartment.salesAreaName;
		state.changeOfAddress.apartment.city = apartment.city;
		state.changeOfAddress.apartment.province = apartment.province;
	} else {
		state.changeOfAddress.apartment = {
			apartmentId: "-1",
			streetType: "",
			streetName: "",
			streetNumber: "",
			floor: "",
			stair: "",
			door: "",
			network: "",
			serviceableStatus: "",
			salesAreaName: "",
			city: "",
			province: ""
		};
	}
};

export default {
	SAVE_ADDRESSES,
	SAVE_APARTMENTS,
	RESET_ADDRESSES_AND_APARTMENTS,
	SET_ADDRESS_SEARCHED_COORDS,
	UPDATE_ADDRESS,
	UPDATE_APARTMENT
};
